@media only screen and (min-width: 768px){}@media only screen and (max-width: 767px){}

.red {
    color: #FF0000;
}

.button-link {
    border: none;
    font-size: 12px;
    color:#FFF;
    background-color: #000;
    height: 15px!important;
    width: 15px!important;
    border-radius: 50%;
    line-height: 15px!important;
    padding: 0px;
    margin-top: 7px!important;
}

.link-cat {
    float: left;
    background-color: transparent;
    border: 0;
    text-transform: uppercase;
}

.link-cat:hover, .button-category-link:hover{
    color:#F7CA18;
}

.link-cat:focus, .button-link:focus, .button-category-link:focus{
    outline:0;
}

.button-category-link {
    background-color: transparent;
    border:0;
}


.panel {
    background-color: inherit;
}


.login-form {
    margin: 0 auto 30px;
    border-bottom: 1px solid #333;
    display: block;
    width: 100px;

}

.input-refresh {
    vertical-align: middle;
    padding: 0px;
    margin-right: -5px;
}

.fullwidth {
    width: 100%;
}

.facts-text {
    padding:15px;
    font-family: 'Roboto Condensed', sans-serif;
}

.shop-menu-button {
    font-family: Raleway;
    text-transform: uppercase;
    line-height: 54px;
    font-weight: 600;
    font-style: normal;
    color: #111111;
    font-size: 14px;

}

.shop-menu-subbutton, .shop-menu-button {
    background: transparent;
    border-left: none;
    border-top: none;
    border-right: none;
    min-width: 200px !important;
    width:100%;
}

.shop-menu-subbutton {
    border-left: 1px solid rgba(0,0,0,.05)!important;
}

.shop-menu-subbutton:focus, .shop-menu-button:focus {
  outline:0;
}

.shop-menu-child {
    float:left;
    white-space: nowrap;
    margin-left: 12px;
    padding-left: 12px;
    padding-right: 12px;
    width: 25%;
    position: relative;
}

.icon-spacing {
    margin-left: 30px;
}

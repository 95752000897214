/* --- Animation --- */
#animation {
    height: 800px;
    text-align: center;
}

.planet img {
    -webkit-animation: rotation 45s linear infinite;
    -moz-animation: rotation 45s linear infinite;
    -ms-animation: rotation 45s linear infinite;
    -o-animation: rotation 45s linear infinite;
    animation: rotation 45s linear infinite;
    margin-bottom: 150px;
    margin-top: 150px;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-ms-keyframes rotation {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}
@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#animation.animate .heart-1 {
    -webkit-transform: rotate(-45deg) translate(350px) rotate(45deg);
    -moz-transform: rotate(-45deg) translate(350px) rotate(45deg);
    -ms-transform: rotate(-45deg) translate(350px) rotate(45deg);
    -o-transform: rotate(-45deg) translate(350px) rotate(45deg);
    transform: rotate(-45deg) translate(350px) rotate(45deg);
}
#animation.animate .heart-2 {
    -webkit-transform: rotate(0deg) translate(350px) rotate(0deg);
    -moz-transform: rotate(0deg) translate(350px)rotate(0deg);
    -ms-transform: rotate(0deg) translate(350px)rotate(0deg);
    -o-transform: rotate(0deg) translate(350px)rotate(0deg);
    transform: rotate(0deg) translate(350px)rotate(0deg);
}
#animation.animate .heart-3 {
    -webkit-transform: rotate(45deg) translate(350px) rotate(-45deg);
    -moz-transform: rotate(45deg) translate(350px) rotate(-45deg);
    -ms-transform: rotate(45deg) translate(350px) rotate(-45deg);
    -o-transform: rotate(45deg) translate(350px) rotate(-45deg);
    transform: rotate(45deg) translate(350px) rotate(-45deg);
}
#animation.animate .heart-4 {
    -webkit-transform: rotate(90deg) translate(350px) rotate(-90deg);
    -moz-transform: rotate(90deg) translate(350px) rotate(-90deg);
    -ms-transform: rotate(90deg) translate(350px) rotate(-90deg);
    -o-transform: rotate(90deg) translate(350px) rotate(-90deg);
    transform: rotate(90deg) translate(350px) rotate(-90deg);
}
#animation.animate .heart-5 {
    -webkit-transform: rotate(135deg) translate(350px) rotate(-135deg);
    -moz-transform: rotate(135deg) translate(350px) rotate(-135deg);
    -ms-transform: rotate(90deg) translate(350px) rotate(-90deg);
    -o-transform: rotate(135deg) translate(350px) rotate(-135deg);
    transform: rotate(135deg) translate(350px) rotate(-135deg);
}
#animation.animate .heart-6 {
    -webkit-transform: rotate(180deg) translate(350px) rotate(-180deg);
    -moz-transform: rotate(180deg) translate(350px) rotate(-180deg);
    -ms-transform: rotate(90deg) translate(350px) rotate(-90deg);
    -o-transform: rotate(180deg) translate(350px) rotate(-180deg);
    transform: rotate(180deg) translate(350px) rotate(-180deg);
}
#animation.animate .heart-7 {
    -webkit-transform: rotate(225deg) translate(350px) rotate(-225deg);
    -moz-transform: rotate(225deg) translate(350px) rotate(-225deg);
    -ms-transform: rotate(90deg) translate(350px) rotate(-90deg);
    -o-transform: rotate(225deg) translate(350px) rotate(-225deg);
    transform: rotate(225deg) translate(350px) rotate(-225deg);
}

.heart {
    left: -webkit-calc(50% - 26px);
    left: -moz-calc(50% - 26px);
    left: -ms-calc(50% - 26px);
    left: -o-calc(50% - 26px);
    left: calc(50% - 26px);
    position: absolute;
    top: 375px;
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -ms-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}
.heart img {
    cursor: pointer;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}
.heart img:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}


@media (max-width: 767px) {
    #animation {
        height: 400px;
    }
    .planet img {
        -webkit-animation: rotation 15s linear infinite;
        -moz-animation: rotation 15s linear infinite;
        -ms-animation: rotation 15s linear infinite;
        -o-animation: rotation 15s linear infinite;
        animation: rotation 15s linear infinite;
        height: 200px;
        margin-bottom: 100px;
        margin-top: 100px;
        width: 210px;
    }

    #animation.animate .heart-1 {
        -webkit-transform: rotate(-45deg) translate(130px) rotate(45deg);
        -moz-transform: rotate(-45deg) translate(130px) rotate(45deg);
        -ms-transform: rotate(-45deg) translate(130px) rotate(45deg);
        -o-transform: rotate(-45deg) translate(130px) rotate(45deg);
        transform: rotate(-45deg) translate(130px) rotate(45deg);
    }
    #animation.animate .heart-2 {
        -webkit-transform: rotate(0deg) translate(130px) rotate(0deg);
        -moz-transform: rotate(0deg) translate(130px)rotate(0deg);
        -ms-transform: rotate(0deg) translate(130px)rotate(0deg);
        -o-transform: rotate(0deg) translate(130px)rotate(0deg);
        transform: rotate(0deg) translate(130px)rotate(0deg);
    }
    #animation.animate .heart-3 {
        -webkit-transform: rotate(45deg) translate(130px) rotate(-45deg);
        -moz-transform: rotate(45deg) translate(130px) rotate(-45deg);
        -ms-transform: rotate(45deg) translate(130px) rotate(-45deg);
        -o-transform: rotate(45deg) translate(130px) rotate(-45deg);
        transform: rotate(45deg) translate(130px) rotate(-45deg);
    }
    #animation.animate .heart-4 {
        -webkit-transform: rotate(90deg) translate(130px) rotate(-90deg);
        -moz-transform: rotate(90deg) translate(130px) rotate(-90deg);
        -ms-transform: rotate(90deg) translate(130px) rotate(-90deg);
        -o-transform: rotate(90deg) translate(130px) rotate(-90deg);
        transform: rotate(90deg) translate(130px) rotate(-90deg);
    }
    #animation.animate .heart-5 {
        -webkit-transform: rotate(135deg) translate(130px) rotate(-135deg);
        -moz-transform: rotate(135deg) translate(130px) rotate(-135deg);
        -ms-transform: rotate(90deg) translate(130px) rotate(-90deg);
        -o-transform: rotate(135deg) translate(130px) rotate(-135deg);
        transform: rotate(135deg) translate(130px) rotate(-135deg);
    }
    #animation.animate .heart-6 {
        -webkit-transform: rotate(180deg) translate(130px) rotate(-180deg);
        -moz-transform: rotate(180deg) translate(130px) rotate(-180deg);
        -ms-transform: rotate(90deg) translate(130px) rotate(-90deg);
        -o-transform: rotate(180deg) translate(130px) rotate(-180deg);
        transform: rotate(180deg) translate(130px) rotate(-180deg);
    }
    #animation.animate .heart-7 {
        -webkit-transform: rotate(225deg) translate(130px) rotate(-225deg);
        -moz-transform: rotate(225deg) translate(130px) rotate(-225deg);
        -ms-transform: rotate(90deg) translate(130px) rotate(-90deg);
        -o-transform: rotate(225deg) translate(130px) rotate(-225deg);
        transform: rotate(225deg) translate(130px) rotate(-225deg);
    }

    .heart {
        left: -webkit-calc(50% - 28px);
        left: -moz-calc(50% - 28px);
        left: -ms-calc(50% - 28px);
        left: -o-calc(50% - 28px);
        left: calc(50% - 28px);
        position: absolute;
        top: 187px;
        -webkit-transition: all 2s ease-in-out;
        -moz-transition: all 2s ease-in-out;
        -ms-transition: all 2s ease-in-out;
        -o-transition: all 2s ease-in-out;
        transition: all 2s ease-in-out;
    }
    .heart img {
        cursor: pointer;
        height: 35%;
        -webkit-transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -ms-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out;
        width: 35%;
    }
    .heart img:hover {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
}

/* --- Menu colors --- */
.main-menu-wrap > ul > li:nth-child(4n+1):hover > a {
    background-color: #add143;
}
.main-menu-wrap > ul > li:nth-child(4n+1):hover > .tn-mega-menu-col,
.main-menu-wrap > ul > li:nth-child(4n+1):hover > .tn-dropdown-menu {
    border-color: #add143;
}
.main-menu-wrap > ul > li:nth-child(4n+1):hover > .tn-mega-menu-col a:hover,
.main-menu-wrap > ul > li:nth-child(4n+1):hover > .tn-dropdown-menu a:hover {
    color: #add143;
}
.main-menu-wrap > ul > li:nth-child(4n+2):hover > a {
    background-color: #159cd8;
}
.main-menu-wrap > ul > li:nth-child(4n+2):hover > .tn-mega-menu-col,
.main-menu-wrap > ul > li:nth-child(4n+2):hover > .tn-dropdown-menu {
    border-color: #159cd8;
}
.main-menu-wrap > ul > li:nth-child(4n+2):hover > .tn-mega-menu-col a:hover,
.main-menu-wrap > ul > li:nth-child(4n+2):hover > .tn-dropdown-menu a:hover {
    color: #159cd8;
}
.main-menu-wrap > ul > li:nth-child(4n+3):hover > a {
    background-color: #ea7d46;
}
.main-menu-wrap > ul > li:nth-child(4n+3):hover > .tn-mega-menu-col,
.main-menu-wrap > ul > li:nth-child(4n+3):hover > .tn-dropdown-menu {
    border-color: #ea7d46;
}
.main-menu-wrap > ul > li:nth-child(4n+3):hover > .tn-mega-menu-col a:hover,
.main-menu-wrap > ul > li:nth-child(4n+3):hover > .tn-dropdown-menu a:hover {
    color: #ea7d46;
}
.main-menu-wrap > ul > li:nth-child(4n+4):hover > a {
    background-color: #9eb253;
}
.main-menu-wrap > ul > li:nth-child(4n+4):hover > .tn-mega-menu-col,
.main-menu-wrap > ul > li:nth-child(4n+4):hover > .tn-dropdown-menu {
    border-color: #9eb253;
}
.main-menu-wrap > ul > li:nth-child(4n+4):hover > .tn-mega-menu-col a:hover,
.main-menu-wrap > ul > li:nth-child(4n+4):hover > .tn-dropdown-menu a:hover {
    color: #9eb253;
}

/* --- Menu --- */
.tn-main-menu-wrap {
    -webkit-box-shadow: 0 0 20px 0 #add143;
    -moz-box-shadow: 0 0 20px 0 #add143;
    -ms-box-shadow: 0 0 20px 0 #add143;
    -o-box-shadow: 0 0 20px 0 #add143;
    box-shadow: 0 0 20px 0 #add143;
}

/* --- Login Popup --- */
.tn-login-content-wrap .tn-login-inner i {
    top: 0;
}
.tn-login-content-wrap .tn-login-inner form .form-type-password i {
    top: 49px;
}
#tn-forgot-pass-inner .block-forgot-password-block form .form-type-textfield > i {
    top: 0;
}

/* --- Header --- */
.logo-area-inner {
    margin-top: 1px;
}
.tn-login-element {
    font-size: 16px;
    font-weight: 900;
}
.tn-top-menu li a {
    font-size: 16px;
    font-weight: 900;
}
.tn-top-menu li a:hover,
.tn-top-menu li a:focus {
    background: transparent;
    color: #ea7d46;
}

/* --- Body --- */
.body-wrap {
    position: relative;
}
.content-wrap .tn-container {
    background-color: #ece5d2;
    padding-bottom: 40px;
    padding-top: 40px;
}
.content-wrap .home-tn-container {
    padding-bottom: 0;
}
.tn-block-wrap {
    margin-bottom: 0;
}
.tn-container .tn-section-full-width-wrap {
    margin-bottom: 0;
    margin-left: -12px;
    margin-right: -12px;
}
.body-wrap .tn-section-full-width-wrap {
    overflow:visible;
    margin-bottom: 0;
}
.block-title {
    color: #ea7d46;
}
.tn-section-content-wrap,
.tn-single-content-wrap {
    color: #333;
    font-family: 'Roboto Condensed', sans-serif;
    padding-bottom: 40px;
}
.tn-section-content-wrap,
.tn-single-content-wrap a {
    color: #333;
}
.tn-breadcrumbs-inner {
    font-family: 'Oswald', sans-serif;
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: -30px;
}
.slider-wrap {
    background-color: #fff;
}
.pager li > a, .pager li > span {
    border-radius: 0;
    border: 0;
}
.item-list ul.pager li {
    box-shadow: none;
}
.item-list ul.pager li.pager-previous > a,
.item-list ul.pager li.pager-first > a,
.item-list ul.pager li.pager-next > a,
.item-list ul.pager li.pager-last > a,
.item-list ul.pager li.pager-item > a {
    border: 1px solid #000;
}
.row > .row {
    margin-left: 12px;
    margin-right: 12px;
}
#location-form {
    margin-bottom: 20px;
}
#location-form input {
    border-radius: 0;
    width: 100%;
}
.btn-special {
    border-radius: 0;
    border: 1px solid #333;
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 0;
    text-transform: uppercase;
    transition: all .35s;
}
.btn-special span {
    display: block;
    background-color: #333;
    border: 1px solid #fff;
    padding: 5px 20px;
    letter-spacing: 1px;
}
.btn-special:hover {
    color: #ea7d46 !important;
}
#locations {
    background-color: #f0ede7;
    height: 450px;
    padding: 20px 40px 20px 20px;
    overflow-x: auto;
}
#locations .data-source {
    display: none;
}
#locations .data-display li {
    cursor: pointer;
}
#locations ul {
    margin: 0;
}
#locations li {
    background-image: url('../../img/maps-leaf.png');
    background-repeat: no-repeat;
    background-position: left center;
    list-style-type: none;
    padding-left: 60px;
}
#locations a:hover {
    text-decoration: none;
}
.widget-title h3,
.block-title-wrap h3 {
    background-color: #ece5d2;
    color: #ea7d46;
    font-weight: 900;
    padding-left: 3px;
}
.product-categories .cat-item {
    font-family: 'Roboto Condensed', sans-serif;
}
.product-subcategories li {
    background-image: url('../../img/categories-leaf.png');
    background-position: left center;
    background-repeat: no-repeat;
    display: block;
    text-align: left;
    padding-left: 30px;
    margin-left: 5px;
}
.product-subcategories a {
    float: none;
    font-weight: 400;
}
.woocommerce ul.products li.product h3 {
    margin-top: 15px;
}
.woocommerce ul.products li.product a:hover {
    color: #333;
}
.woocommerce ul.products li.product .price {
    line-height: 18px;
}
.price .amount {
    line-height: 18px;
}
.shop-message {
    padding: 5px 10px;
}
.bg-success {
    background-color: #f8fff0;
    border: 1px solid #be7;
    color: #234600;
}
.tn-sticky-sidebar .woocommerce.widget_shopping_cart .product_list_widget li input[type=submit]:hover {
    background-color: inherit;
    color: inherit;
}
.product-container {
    padding-top: 10px !important;
}
.delete-button {
    margin-left: -20px;
}
.green {
    color: #697;
}
.summary.entry-summary p {
    padding: 5px 0;
}
.summary.entry-summary h1.product_title.entry-title {
    font-size: 24px !important;
    font-weight: 700 !important;
}
.summary.entry-summary strong {
    font-weight: 700;
}
.views-field.views-field-edit-quantity select,
.summary.entry-summary select {
    border: 1px dashed rgba(0, 0, 0, .1);
}
.product_meta.product-categories {
    font-family: 'Roboto Condensed', sans-serif;
}
#views-form-commerce-cart-form-default {
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
    overflow: hidden;
}
.totals {
    padding-bottom: 40px;
}
.views-field-line-item-photo {
    width: 10%;
}
.views-field-line-item-title {
    line-height: 18px;
    width: 40%;
}
.views-field-line-item-title small {
    font-size: 12px;
}
.views-field-commerce-unit-price {
    width: 15%;
}
.views-field-edit-quantity {
    width: 10%;
}
.views-field-edit-delete {
    width: 10%;
}
.views-field-commerce-total {
    width: 15%;
}
.line-item-total-small {
    font-size: 20px;
}
.history-table thead tr th {
    background-color: #ccc;
    text-transform: uppercase;
    border-left: 0;
}

.form-control {
    border: 1px solid #cbcbcb;
    border-radius: 0;
}

/* --- Footer --- */
#tn-footer {
    background-color: transparent;
}
#tn-footer .tn-container {
    background-color: #ece5d2;
}
.tn-sidebar-footer-wrap {
    -webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.5);
    -ms-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.5);
    -o-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.5);
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.5);
}
.tn-copyright-wrap {
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 0;
}
.tn-copyright-inner {
    background-color: #fff;
    padding-bottom: 30px;
    padding-top: 30px;
}
#tn-footer .widget-title h3,
#tn-footer .block-title-wrap h3 {
    background-color: #fff;
    color: #000;
    font-weight: 900;
}
.copyright {
    font-family: 'Ostrich Sans', sans-serif;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
}

/* --- Words --- */
.text-l {
    background: url('../../img/text-l-1.png') repeat-y 40px top;
}
.text-r {
    background: url('../../img/text-r-1.png') repeat-y right 40px top;
}

.tn-block-image-content-wrap {
    background: rgba(255, 255, 255, .6);
}
.tn-block-image-content p {
    color: #333;
    text-shadow: none;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.tn-block-image-read-more.tn-read-more {
    background: #fff;
    color: #333;
    -webkit-box-shadow: 0 0 0 3px #777;
    font-family: 'Roboto Condensed', sans-serif;
    box-shadow: 0 0 0 3px #777;
    transition: none;
}
.tn-block-image-read-more.tn-read-more:after {
    transition: none;
}

/* --- Slider --- */
.tn-module-slider-content-wrap {
    border-left: 9px solid #ea7d46;
}

/* --- Blog --- */
.tn-module4-content {
    background-color: #ece5d2;
}

/* --- Content area shadow --- */
.content-wrap > div {
    overflow: hidden;
}
.content-wrap > div > .tn-container {
    -webkit-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5);
    -moz-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5);
    -ms-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5);
    -o-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5);
    box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5);
}

#tn-footer {
    overflow: hidden;
}
#tn-footer > .tn-container {
    -webkit-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5), inset 0 20px 20px -20px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5), inset 0 20px 20px -20px rgba(0,0,0,0.5);
    -ms-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5), inset 0 20px 20px -20px rgba(0,0,0,0.5);
    -o-box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5), inset 0 20px 20px -20px rgba(0,0,0,0.5);
    box-shadow: 0 0 20px 5px rgba(173, 209, 67, .5), inset 0 20px 20px -20px rgba(0,0,0,0.5);
}

.woocommerce #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce #content div.product .woocommerce-tabs ul.tabs:before, .woocommerce #content div.product div.thumbnails:after, .woocommerce #content div.product div.thumbnails:before, .woocommerce .col2-set:after, .woocommerce .col2-set:before, .woocommerce div.product .woocommerce-tabs ul.tabs:after, .woocommerce div.product .woocommerce-tabs ul.tabs:before, .woocommerce div.product div.thumbnails:after, .woocommerce div.product div.thumbnails:before, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:before, .woocommerce-page #content div.product div.thumbnails:after, .woocommerce-page #content div.product div.thumbnails:before, .woocommerce-page .col2-set:after, .woocommerce-page .col2-set:before, .woocommerce-page div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page div.product .woocommerce-tabs ul.tabs:before, .woocommerce-page div.product div.thumbnails:after, .woocommerce-page div.product div.thumbnails:before {
	content: " ";
	display: table
}
.woocommerce #content div.product #reviews .comment:after, .woocommerce #content div.product .woocommerce-tabs, .woocommerce #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce #content div.product div.thumbnails a.first, .woocommerce #content div.product div.thumbnails:after, .woocommerce .cart-collaterals:after, .woocommerce .col2-set:after, .woocommerce .related:after, .woocommerce .upsells.products:after, .woocommerce .woocommerce-pagination ul.page-numbers:after, .woocommerce div.product #reviews .comment:after, .woocommerce div.product .woocommerce-tabs, .woocommerce div.product .woocommerce-tabs ul.tabs:after, .woocommerce div.product div.thumbnails a.first, .woocommerce div.product div.thumbnails:after, .woocommerce ul.products, .woocommerce ul.products li.first, .woocommerce ul.products:after, .woocommerce-page #content div.product #reviews .comment:after, .woocommerce-page #content div.product .woocommerce-tabs, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page #content div.product div.thumbnails a.first, .woocommerce-page #content div.product div.thumbnails:after, .woocommerce-page .cart-collaterals:after, .woocommerce-page .col2-set:after, .woocommerce-page .related:after, .woocommerce-page .upsells.products:after, .woocommerce-page .woocommerce-pagination ul.page-numbers:after, .woocommerce-page div.product #reviews .comment:after, .woocommerce-page div.product .woocommerce-tabs, .woocommerce-page div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page div.product div.thumbnails a.first, .woocommerce-page div.product div.thumbnails:after, .woocommerce-page ul.products, .woocommerce-page ul.products li.first, .woocommerce-page ul.products:after {
	clear: both
}
.woocommerce .col2-set, .woocommerce-page .col2-set {
	width: 100%
}
.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
	float: left;
	width: 48%
}
.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
	float: right;
	width: 48%
}
.woocommerce img, .woocommerce-page img {
	height: auto;
	max-width: 100%
}
.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images {
	float: left;
	width: 48%
}
.woocommerce #content div.product div.thumbnails a, .woocommerce div.product div.thumbnails a, .woocommerce-page #content div.product div.thumbnails a, .woocommerce-page div.product div.thumbnails a {
	float: left;
	width: 30.75%;
	margin-right: 3.8%;
	margin-bottom: 1em
}
.woocommerce #content div.product div.thumbnails a.last, .woocommerce div.product div.thumbnails a.last, .woocommerce-page #content div.product div.thumbnails a.last, .woocommerce-page div.product div.thumbnails a.last {
	margin-right: 0
}
.woocommerce #content div.product div.thumbnails.columns-1 a, .woocommerce div.product div.thumbnails.columns-1 a, .woocommerce-page #content div.product div.thumbnails.columns-1 a, .woocommerce-page div.product div.thumbnails.columns-1 a {
	width: 100%;
	margin-right: 0;
	float: none
}
.woocommerce #content div.product div.thumbnails.columns-2 a, .woocommerce div.product div.thumbnails.columns-2 a, .woocommerce-page #content div.product div.thumbnails.columns-2 a, .woocommerce-page div.product div.thumbnails.columns-2 a {
	width: 48%
}
.woocommerce #content div.product div.thumbnails.columns-4 a, .woocommerce div.product div.thumbnails.columns-4 a, .woocommerce-page #content div.product div.thumbnails.columns-4 a, .woocommerce-page div.product div.thumbnails.columns-4 a {
	width: 22.05%
}
.woocommerce #content div.product div.thumbnails.columns-5 a, .woocommerce div.product div.thumbnails.columns-5 a, .woocommerce-page #content div.product div.thumbnails.columns-5 a, .woocommerce-page div.product div.thumbnails.columns-5 a {
	width: 16.9%
}
.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary {
	float: right;
	width: 48%
}
.woocommerce #content div.product .woocommerce-tabs ul.tabs li, .woocommerce div.product .woocommerce-tabs ul.tabs li, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li, .woocommerce-page div.product .woocommerce-tabs ul.tabs li {
	display: inline-block
}
.woocommerce #content div.product #reviews .comment:after, .woocommerce #content div.product #reviews .comment:before, .woocommerce .related:after, .woocommerce .related:before, .woocommerce .upsells.products:after, .woocommerce .upsells.products:before, .woocommerce .woocommerce-pagination ul.page-numbers:after, .woocommerce .woocommerce-pagination ul.page-numbers:before, .woocommerce div.product #reviews .comment:after, .woocommerce div.product #reviews .comment:before, .woocommerce ul.products:after, .woocommerce ul.products:before, .woocommerce-page #content div.product #reviews .comment:after, .woocommerce-page #content div.product #reviews .comment:before, .woocommerce-page .related:after, .woocommerce-page .related:before, .woocommerce-page .upsells.products:after, .woocommerce-page .upsells.products:before, .woocommerce-page .woocommerce-pagination ul.page-numbers:after, .woocommerce-page .woocommerce-pagination ul.page-numbers:before, .woocommerce-page div.product #reviews .comment:after, .woocommerce-page div.product #reviews .comment:before, .woocommerce-page ul.products:after, .woocommerce-page ul.products:before {
	content: " ";
	display: table
}
.woocommerce #content div.product #reviews .comment img, .woocommerce div.product #reviews .comment img, .woocommerce-page #content div.product #reviews .comment img, .woocommerce-page div.product #reviews .comment img {
	float: right;
	height: auto
}
.woocommerce .related, .woocommerce .upsells.products, .woocommerce-page .related, .woocommerce-page .upsells.products {
	clear: both
}
.woocommerce .related ul, .woocommerce .related ul.products, .woocommerce .upsells.products ul, .woocommerce .upsells.products ul.products, .woocommerce-page .related ul, .woocommerce-page .related ul.products, .woocommerce-page .upsells.products ul, .woocommerce-page .upsells.products ul.products {
	float: none
}
.woocommerce .related ul li.product, .woocommerce .related ul.products li.product, .woocommerce .upsells.products ul li.product, .woocommerce .upsells.products ul.products li.product, .woocommerce-page .related ul li.product, .woocommerce-page .related ul.products li.product, .woocommerce-page .upsells.products ul li.product, .woocommerce-page .upsells.products ul.products li.product {
	width: 48%
}
.woocommerce .related ul li.product img, .woocommerce .related ul.products li.product img, .woocommerce .upsells.products ul li.product img, .woocommerce .upsells.products ul.products li.product img, .woocommerce-page .related ul li.product img, .woocommerce-page .related ul.products li.product img, .woocommerce-page .upsells.products ul li.product img, .woocommerce-page .upsells.products ul.products li.product img {
	width: 100%;
	height: auto
}
.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
	float: left;
	margin: 0 3.8% 2.992em 0;
	padding: 0;
	position: relative;
	width: 22.05%
}
.woocommerce ul.products li.last, .woocommerce-page ul.products li.last {
	margin-right: 0
}
.woocommerce-page.columns-1 ul.products li.product, .woocommerce.columns-1 ul.products li.product {
	width: 100%;
	margin-right: 0
}
.woocommerce-page.columns-2 ul.products li.product, .woocommerce.columns-2 ul.products li.product {
	width: 48%
}
.woocommerce-page.columns-3 ul.products li.product, .woocommerce.columns-3 ul.products li.product {
	width: 30.75%
}
.woocommerce-page.columns-5 ul.products li.product, .woocommerce.columns-5 ul.products li.product {
	width: 16.95%
}
.woocommerce-page.columns-6 ul.products li.product, .woocommerce.columns-6 ul.products li.product {
	width: 13.5%
}
.woocommerce .woocommerce-result-count, .woocommerce-page .woocommerce-result-count {
	float: left
}
.woocommerce .woocommerce-ordering, .woocommerce-page .woocommerce-ordering {
	float: right
}
.woocommerce .woocommerce-pagination ul.page-numbers li, .woocommerce-page .woocommerce-pagination ul.page-numbers li {
	display: inline-block
}
.woocommerce #content table.cart img, .woocommerce table.cart img, .woocommerce-page #content table.cart img, .woocommerce-page table.cart img {
	height: auto
}
.woocommerce #content table.cart td.actions, .woocommerce table.cart td.actions, .woocommerce-page #content table.cart td.actions, .woocommerce-page table.cart td.actions {
	text-align: right
}
.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
	width: 80px
}
.woocommerce #content table.cart td.actions .coupon, .woocommerce table.cart td.actions .coupon, .woocommerce-page #content table.cart td.actions .coupon, .woocommerce-page table.cart td.actions .coupon {
	float: left
}
.woocommerce #content table.cart td.actions .coupon label, .woocommerce table.cart td.actions .coupon label, .woocommerce-page #content table.cart td.actions .coupon label, .woocommerce-page table.cart td.actions .coupon label {
	display: none
}
.woocommerce .cart-collaterals .shipping_calculator:after, .woocommerce .cart-collaterals .shipping_calculator:before, .woocommerce .cart-collaterals:after, .woocommerce .cart-collaterals:before, .woocommerce form .form-row:after, .woocommerce form .form-row:before, .woocommerce ul.cart_list li:after, .woocommerce ul.cart_list li:before, .woocommerce ul.product_list_widget li:after, .woocommerce ul.product_list_widget li:before, .woocommerce-page .cart-collaterals .shipping_calculator:after, .woocommerce-page .cart-collaterals .shipping_calculator:before, .woocommerce-page .cart-collaterals:after, .woocommerce-page .cart-collaterals:before, .woocommerce-page form .form-row:after, .woocommerce-page form .form-row:before, .woocommerce-page ul.cart_list li:after, .woocommerce-page ul.cart_list li:before, .woocommerce-page ul.product_list_widget li:after, .woocommerce-page ul.product_list_widget li:before {
	content: " ";
	display: table
}
.woocommerce .cart-collaterals, .woocommerce-page .cart-collaterals {
	width: 100%
}
.woocommerce .cart-collaterals .related, .woocommerce-page .cart-collaterals .related {
	width: 30.75%;
	float: left
}
.woocommerce .cart-collaterals .cross-sells, .woocommerce-page .cart-collaterals .cross-sells {
	width: 48%;
	float: left
}
.woocommerce .cart-collaterals .cross-sells ul.products, .woocommerce-page .cart-collaterals .cross-sells ul.products {
	float: none
}
.woocommerce .cart-collaterals .cross-sells ul.products li, .woocommerce-page .cart-collaterals .cross-sells ul.products li {
	width: 48%
}
.woocommerce .cart-collaterals .shipping_calculator, .woocommerce-page .cart-collaterals .shipping_calculator {
	width: 48%;
	clear: right;
	float: right
}
.woocommerce .cart-collaterals .shipping_calculator:after, .woocommerce form .form-row-wide, .woocommerce form .form-row:after, .woocommerce ul.cart_list li:after, .woocommerce ul.product_list_widget li:after, .woocommerce-page .cart-collaterals .shipping_calculator:after, .woocommerce-page form .form-row-wide, .woocommerce-page form .form-row:after, .woocommerce-page ul.cart_list li:after, .woocommerce-page ul.product_list_widget li:after {
	clear: both
}
.woocommerce .cart-collaterals .shipping_calculator .col2-set .col-1, .woocommerce .cart-collaterals .shipping_calculator .col2-set .col-2, .woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-1, .woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
	width: 47%
}
.woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
	float: right;
	width: 48%
}
.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img, .woocommerce-page ul.cart_list li img, .woocommerce-page ul.product_list_widget li img {
	float: right;
	height: auto
}
.woocommerce form .form-row label, .woocommerce-page form .form-row label {
	display: block
}
.woocommerce form .form-row label.checkbox, .woocommerce-page form .form-row label.checkbox {
	display: inline
}
.woocommerce form .form-row select, .woocommerce-page form .form-row select {
	width: 100%
}
.woocommerce form .form-row .input-text, .woocommerce-page form .form-row .input-text {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%
}
.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last {
	float: left;
	width: 47%;
	overflow: visible
}
.woocommerce #payment #place_order, .woocommerce form .form-row-last, .woocommerce-page #payment #place_order, .woocommerce-page form .form-row-last {
	float: right
}
.woocommerce #payment .form-row select, .woocommerce-page #payment .form-row select {
	width: auto
}
.woocommerce #payment .terms, .woocommerce-page #payment .terms {
	text-align: right;
	padding: 0 1em
}
.twentyfourteen .tfwc {
	padding: 12px 10px 0;
	max-width: 474px;
	margin: 0 auto
}
.twentyfourteen .tfwc .product .entry-summary {
	padding: 0!important;
	margin: 0 0 1.618em!important
}
.twentyfourteen .tfwc div.product.hentry.has-post-thumbnail {
	margin-top: 0
}
.twentyfourteen .tfwc .product .images img {
	margin-bottom: 1em
}

@media screen and (min-width:673px) {
.twentyfourteen .tfwc {
	padding-right: 30px;
	padding-left: 30px
}
}

@media screen and (min-width:1040px) {
.twentyfourteen .tfwc {
	padding-right: 15px;
	padding-left: 15px
}
}

@media screen and (min-width:1110px) {
.twentyfourteen .tfwc {
	padding-right: 30px;
	padding-left: 30px
}
}

@media screen and (min-width:1218px) {
.twentyfourteen .tfwc {
	margin-right: 54px
}
.full-width .twentyfourteen .tfwc {
	margin-right: auto
}
}
.twentyfifteen .t15wc {
	padding-left: 7.6923%;
	padding-right: 7.6923%;
	padding-top: 7.6923%;
	margin-bottom: 7.6923%;
	background: #fff;
	box-shadow: 0 0 1px rgba(0,0,0,.15)
}
.twentyfifteen .t15wc .page-title {
	margin-left: 0
}

@media screen and (min-width:38.75em) {
.twentyfifteen .t15wc {
	margin-right: 7.6923%;
	margin-left: 7.6923%;
	margin-top: 8.3333%
}
}

@media screen and (min-width:59.6875em) {
.twentyfifteen .t15wc {
	margin-left: 8.3333%;
	margin-right: 8.3333%;
	padding: 10%
}
.single-product .twentyfifteen .entry-summary {
	padding: 0!important
}
}

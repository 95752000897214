.tn-login-content-wrap .tn-login-inner form input[type=submit]{
	position: inherit;
	width: 100%;
	font-family: "Playfair Display";
	text-transform: uppercase;
	font-weight: 300!important;
	-webkit-transition: all .35s!important;
    -moz-transition: all .35s!important;
    -ms-transition: all .35s!important;
    -o-transition: all .35s!important;
    transition: all .35s!important;
    background-color: #fff;
    color: #333333;
    border-radius: 0;
    border: 1px solid #999;
    margin: 0 !important;
    font-size: 15px !important;

}
.tn-login-content-wrap .tn-login-inner form input[type=text],
.tn-login-content-wrap .tn-login-inner form input[type=password]{
    border-bottom-color: #999;
    border-right: 0;
    border-left: 0;
    border-top: 0;
}
.tn-login-content-wrap .tn-login-inner form input[type=submit]:hover{
	background: #333;
    color: #fff;
}
.tn-login-content-wrap .tn-login-inner form label{
	display: none !important;
}
#tn-forgot-pass-inner .block-forgot-password-block form input[type=submit]{
	position: inherit;
	width: 100%;
	font-family: "Playfair Display";
	text-transform: uppercase;
	font-weight: 300!important;
	-webkit-transition: all .35s!important;
    -moz-transition: all .35s!important;
    -ms-transition: all .35s!important;
    -o-transition: all .35s!important;
    transition: all .35s!important;
    background-color: #fff;
    color: #333333;
    border-radius: 0;
    border: 1px solid #999;
    margin: 0 !important;
    font-size: 15px !important;
}
#tn-forgot-pass-inner .block-forgot-password-block form input[type=submit]:hover{
	background: #333;
    color: #fff;
}
#tn-forgot-pass-inner .block-forgot-password-block form input[type=text]{
    border-bottom-color: #999;
    border-right: 0;
    border-left: 0;
    border-top: 0;
}
#tn-forgot-pass-inner .block-forgot-password-block form label{
	display: none !important;
}
#tn-forgot-pass-inner .block-forgot-password-block form .form-type-textfield > i{
	position: absolute;
    right: 0;
    top: 15px;
    font-size: 24px;
    line-height: 1;
    padding: 12px;
    color: #999;
}
.tn-login-content-wrap .tn-login-inner i{
    position: absolute;
    right: 0;
    top: 15px;
    font-size: 24px;
    line-height: 1;
    padding: 12px;
    color: #999;
}
.tn-login-content-wrap .tn-login-inner form .form-type-password i{
	position: absolute;
    right: 0;
    top: 83px;
    font-size: 24px;
    line-height: 1;
    padding: 12px;
    color: #999;
}
li.leaf{
	padding: 0;
    margin: 0;
}
/*#tn-top-bar-wrap .tn-top-menu-wrap{
	overflow: hidden;
}*/
#tn-logout i.fa-sign-out{
	margin-right: 0;
	margin-left: 6px;
}
#tn-main-menu-sticky .tn-main-menu-wrap{
	z-index: 490;
}
#tn-main-menu-sticky .contextual-links-region{
	position: inherit;
}
#tn-main-menu-sticky li.expanded,
#tn-main-menu-sticky li.collapsed,
#tn-main-menu-sticky li.leaf{
	padding: 0;
}
#menu-footer-menu > li.menu-item  a{
	color: #8c919a;
}
.flickr_badge_image a::before{
	background: #c69f74;
}
.tn-thumb-wrap a{
    z-index: 1;
}
.tn-module4-content{
    z-index: 101;
}
.item-list{
    border-bottom: 1px dashed rgba(0,0,0,.1);
        padding-bottom: 16px;
}
.item-list ul.pagination{
    text-align: left;

}
.item-list ul.pagination li span{
    padding: 0;
    margin-left: 0;
    margin-right: 9px;

    height: 30px;
    width: 24px;
    text-align: center;
}
.item-list ul.pagination li > a{
	color:#000;
    padding: 8px;
    padding-top: 8px;
    padding-bottom: 6px;
    text-transform: uppercase;
    font-size: 14px;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
    border: 1px solid #ffffff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    transition: transform 0.2s,opacity .35s;
}
.pagination li.disabled {
	display:none;
}
.item-list ul.pagination li span{
    -webkit-box-shadow: 0 0 0 1px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.item-list ul.pagination li.active span{
    padding: 7px;
    padding-top: 8px;
    padding-bottom: 6px;
    -webkit-box-shadow: 0 0 0 0px;
    background-color: #848484;
    border: 1px solid black;
    color: #F5F5F5;
    font-size: 14px;
}.item-list ul.pagination li > a:hover{
    background-color: #333333;
    color: #ffffff;


}
.tn-sidebar-footer-wrap .flickr-widget .flickr_badge_image a{
    width: 33.33333333%;
}
#single-blog-style1 .tn-banner-parallax-wrap{
    height: 188px;
}
.backstretch{
    width: 100%;
}
.zmagazine-shop-catalog{
    position: relative;
}
.zmagazine-shop-catalog .starrating{
    position: absolute;
    bottom: 77px;
    right: 10px;
}
.zmagazine-shop-catalog .commerce-add-to-cart input[type=submit]{
    position: absolute;
    bottom: 0;
    border: 1px solid #111;
    border-radius: 0;
    right: -20px;
    display: none;
    background: #fff;
    color: #000;
    text-transform: uppercase;
    padding: 10px 12px;
    font-weight: 300;
    letter-spacing: 1px !important;
    margin-top:8px !important;
    transition: all .3s !important;
    -webkit-transition: all .3s !important;
}

.zmagazine-shop-catalog .commerce-add-to-cart{
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0px;
}
.views-exposed-form .views-exposed-widget{
    float: right;
    padding: 0;
}
.views-exposed-form .views-exposed-widget select{
    width: 175px;
    padding: 5px 0px;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 12px;
    text-indent: 7px;

}
.views-exposed-form .views-exposed-widget select > option{
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 12px;
    text-indent: 7px;

}
.tn-sticky-sidebar .woocommerce.widget_shopping_cart .product_list_widget li input[type=submit]{
    left:0;
    top: 3px;
    border-radius: 50%;
    padding-bottom: 2px;
    display: none;
    color: #000;
    background-color: transparent;
     transition: all .3s !important;
    -webkit-transition: all .3s !important;
}
.tn-sticky-sidebar .woocommerce.widget_shopping_cart .product_list_widget li p{
    text-transform: uppercase;
}
.tn-sticky-sidebar .woocommerce.widget_shopping_cart .product_list_widget li input[type=submit]:hover{
    background-color: #000;
    color: #fff;
}


.tn-sidebar-sticky .widget_shopping_cart .product_list_widget .line-item-summary{
    border-top: 0;
    padding: 10px;
    background: #e2e2e2;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
}

.tn-sidebar-sticky .widget_shopping_cart .product_list_widget .line-item-summary .line-item-total-label{
    font-size: 100%;
    font-weight: normal;
    margin-right: 5px;
}
.single-product .entry-summary .commerce-add-to-cart{
    overflow: visible;
    top: 27px;
}
.single-product .entry-summary .commerce-add-to-cart label{
    display: none;
}
.single-product .entry-summary .commerce-add-to-cart input[type=text]{
    width:3.631em;
    text-align: center;
    line-height: 32px;
    float: left;
}

.single-product .entry-summary .commerce-add-to-cart input[type=submit]{
    left:65px;
    line-height: 32px;
    top:-1px;
    padding-left: 17px;
    padding-right: 17px;
    border: solid 1px #333;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}
.single-product .entry-summary .product-categories{
    margin-top: 130px;
}
.single-product .entry-summary .commerce-add-to-cart input[type=submit]:hover{
    background-color: #fff;
    color: #333;
}
.single-product .product .images .thumbnails > a:nth-child(3n+0){
    margin-right: 0 !important;
}
#reviews > #comments .user-picture img{
    border-radius: 50%;
    width: 42px;
    height: auto;
    display: block;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    height: auto;
    background: #ebe9eb;
    border: 1px
    solid #e4e1e3;
    margin: 0;
    box-shadow: none;
}
#review_form_wrapper #review_form .comment-form input[type=submit]{
    position: inherit;
    border: 1px solid #333;
    border-radius: 0;
    font-weight: 300;
    color: #515151;
    font-size: 13px;
    text-transform: uppercase;
    background-color: #fff;
     transition: all .3s !important;
    -webkit-transition: all .3s !important;
}
#review_form_wrapper #review_form .comment-form input[type=submit]:hover{

    background-color: #000;
    color: #fff;
}
.view-commerce-cart-form form table{
    width: 100%;
}
.view-commerce-cart-form form table tbody td.views-field-edit-quantity input{
    width: 111px;
    margin: 0 auto;
    text-align: center;
}
.view-commerce-cart-form form table tbody td.views-field-edit-delete input{
    position: inherit;
    padding:5px 7px;
    transition: all .3s !important;
    -webkit-transition: all .3s !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
        border: solid 1px #000;
}
.view-commerce-cart-form form table tbody td.views-field-edit-delete input:hover{
    background-color: transparent;
    color: #000;
}
.view-commerce-cart-form form table thead tr th{
    background-color: #ccc;
    text-transform: uppercase;
    border-left: 0;
}
.view-commerce-cart-form .line-item-summary .line-item-total .line-item-total-raw{
    font-size: 190%;

}
.view-commerce-cart-form .line-item-summary .line-item-total{
    margin-top: 32px;
}
.view-commerce-cart-form .line-item-summary .line-item-total .line-item-total-label{
    font-family: Oswald;
}
.view-commerce-cart-form .form-actions input[type=submit]{
    position: inherit;
    transition: all .3s !important;
    -webkit-transition: all .3s !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
        border: solid 1px #000;
}
.view-commerce-cart-form .form-actions input[type=submit]:hover{
    background-color: transparent;
    color: #000;
}
#commerce-checkout-form-checkout table{
    width: 100%;
}
#commerce-checkout-form-checkout input[type=submit]{
    position: inherit;
    border: solid 1px #000;
}

#commerce-checkout-form-checkout table thead tr th{
    background-color: #ccc;
    text-transform: uppercase;
    border-left: 0;
}
#commerce-checkout-form-checkout fieldset legend{
    font-family: Oswald;
    font-size: 200%;
    margin-bottom: 30px;
}
#commerce-checkout-form-checkout .view-footer{
    margin-top: 22px;
    width: 40%;
    float: right;
}
#commerce-checkout-form-checkout th,#commerce-checkout-form-checkout td{
    text-align: center;
}
#commerce-checkout-form-checkout .component-type-commerce-price-formatted-amount td.component-total{
    font-weight: bold;
    font-size: 115%;
}
.customer_profile_billing.form-wrapper .name-block{

}
.customer_profile_billing.form-wrapper .name-block .form-type-textfield{
    margin-top: 0;
    margin-bottom: 0;
}
.customer_profile_billing.form-wrapper .addressfield-container-inline .form-type-textfield{
    margin-top: 0;
}
fieldset.checkout-buttons{
    margin-bottom: 30px;
}
fieldset.checkout-buttons .checkout-continue:hover{
    background-color: transparent;
    color: #000;
}fieldset.checkout-buttons .checkout-continue{
    border: solid 1px #000;
}
.customer_profile_billing .street-block input[type=text]{
    width: 65%;
}
.checkout-cancel{
    border: 0 !important;
}
#commerce-checkout-form-review input[type=submit]{
    position: inherit;
}
#commerce-checkout-form-review table.checkout-review,#commerce-checkout-form-review table.checkout-review .view-commerce-cart-summary table{
    width: 100%;
}
#commerce-checkout-form-review table.checkout-review{
    border: 0;
}
#commerce-checkout-form-review table.checkout-review .view-footer{
    width: 44% !important;
    float: right;
    margin-top: 22px;
}
#commerce-checkout-form-review  table.checkout-review tr.pane-title td{
    text-align: left;
    font-size: 200%;
    font-family: Oswald;
    padding-bottom: 25px;
    padding-top:25px;
    border-bottom: 1px solid #ccc;
}
#commerce-checkout-form-review  table.checkout-review thead tr th{
    background-color: #ccc;
    text-transform: uppercase;
    border-top: 0;
    border-left: 0;
}
#commerce-checkout-form-review  table.checkout-review tr.pane-data .form-type-item,#commerce-checkout-form-review  table.checkout-review tr.pane-data td.pane-data-full{
    text-align: left;
}
#commerce-checkout-form-review table tbody tr .pane-data-full{
    border: 0;
}
#commerce-checkout-form-review table tbody tr.pane-title,#commerce-checkout-form-review table tbody tr.pane-title > td{
    background-color: transparent;
    border: 0;
}
}
#commerce-checkout-form-review  table.checkout-review .view-footer{
    float: right;
}
#commerce-checkout-form-review  table.checkout-review .view-content{
    margin-top: 15px;
}
#commerce-checkout-form-review  table.checkout-review tr.pane-data{
    background-color: transparent;
}
#commerce-checkout-form-review  table.checkout-review .component-type-commerce-price-formatted-amount .component-total{
    font-weight: bold;
    font-size: 115%;
}
#commerce-checkout-form-review  table.checkout-review .views-align-right{
    padding-right: 10px;
}
#commerce-checkout-form-review table.checkout-review .view-footer .commerce-price-formatted-components tbody tr td{
    padding-right: 10px;
}
#commerce-checkout-form-review .commerce_payment .form-item-commerce-payment-payment-details-credit-card-number input[type=text]{
    width: 40%;
}
ul li,ul li.leaf{
    list-style-image: none;
    list-style: none;
}
.webform-client-form-30 .webform-component--name{

    width: 100%;
}
.webform-client-form-30 .webform-component--phone{

    width: 100%;
}
.webform-client-form-30 .webform-component--email{

    width: 100%;
}
.webform-client-form-30 .webform-component--address{

    width: 100%;
}
.webform-client-form-30 input {
    padding: 7px 12px;
}
#edit-submitted-message {
    width: 100%;
}
.webform-client-form-30 input[type=submit]{
    position: inherit;
    border: 1px solid #ccc;
    font-size: 15px;
    padding: 11px 17px;
    margin-top: 17px;
}
.webform-client-form-30 input[type=submit]:hover{
    background-color: transparent;
    color: #000;

}
.page-user input[type=submit]{
    position: inherit;
    line-height: 9px;
    font-family: -webkit-body;
    font-size: 13px;
    border: 1px solid #333;
    background-color: #fff;
    color: #000;

}
.page-user form{
    background-color: #eee;
    padding: 14px 22px;
}
.page-user input[type=submit]:hover{
    color: #fff;
    background-color: #333;
}ul.primary{
    border:0 !important;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}
ul.primary li a{
    background-color: transparent;
    border: 0;
    padding: 0;
    text-transform: uppercase;
    margin-right: 20px;
    font-size: 90%;
}
ul.primary li a:hover{
    border: 0;
    background-color: transparent;
}
ul.primary li a.active{
    border: 0 !important;
    font-weight: bold;
}
.page-search input[type=submit]{
    position: inherit;
}
#lightbox2-overlay{
    z-index: 1000 !important;
}
#lightbox{
    z-index: 1001 !important;
}
#lightbox a img{
    width: 100% !important;
}
.APTFIN_by_TAP #aptfinbytap_domain-2-AlpinePhotoTiles_container a img{
    width: 10%;
}
.main-menu-wrap .contextual-links-wrapper.contextual-links-processed{
    left: 0;
}
.main-menu-wrap .contextual-links-wrapper.contextual-links-processed ul.contextual-links{
    left: 0;
    width: 120px;
}
#d392ecbd-d804-465b-aa7a-07c6b5f5e919 .tn-category-tags-wrap{
    color: #fff;
}
.style-3.tn-banner-parallax-wrap{
    height: 180px;
}
#tn-main-mobile-menu ul li.expanded > a{
    padding-left: 0;
    font-size: 15px !important;
}
#tn-main-mobile-menu ul li.expanded{
    list-style-image: none;
    list-style: none;

}
#tn-main-menu-sticky li.expanded{
    list-style-image: none;
    list-style: none;
}
.tn-main-menu-wrap .main-menu-wrap.hidden-menu{
    display: none;
}

.page-search .search-form .form-submit{
    padding-right: 32px;
    padding-left: 32px;
    border: 1px solid #000;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}
.page-search .search-form .form-submit:hover{
    color: #000;
    background-color: #fff;
}
.page-search .search-results li.search-result{
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}
.page-search .search-results li.search-result strong{
    font-weight: bold;
}
.node-type-blog #review_form .comment-form label{
    display: block;
}
.page-comment input[type=submit]{
    position: inherit;
    border: 1px solid #000;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}
.page-comment input[type=submit]:hover{
    color: #000;
    background-color: #fff;
}
.tn-module2-inner .tn-module-meta-tags{
    z-index: 480;
}
#zmagazine-gallery-2 a img{
    width: 20%;
    border:1px solid #ffffff;
}
.APTFIN_by_TAP #aptfinbytap_domain-2-AlpinePhotoTiles_container a img{
border:1px solid #ffffff;

}
ul.main-menu > li.active-trail > a.active-trail{
    background: ;
    color: #fff;
}
.tn-single-top-meta-wrap .tn-single-tags.single-meta-right-el{
    margin-left:20px;

}
.tn-single-top-meta-right .tn-single-top-meta-date{
    display:inline-block;
}
.tn-login-element i{
margin-left:3px;
}
.tn-single-tags.single-meta-right-el i.fa-tags{
margin-right:2px;
}

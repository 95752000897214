@media only screen and (max-width: 768px){
.woocommerce #content div.product #reviews .comment:after, .woocommerce #content div.product .woocommerce-tabs, .woocommerce #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce #content div.product div.thumbnails a.first, .woocommerce #content div.product div.thumbnails:after, .woocommerce .cart-collaterals:after, .woocommerce .col2-set:after, .woocommerce .related:after, .woocommerce .upsells.products:after, .woocommerce .woocommerce-pagination ul.page-numbers:after, .woocommerce div.product #reviews .comment:after, .woocommerce div.product .woocommerce-tabs, .woocommerce div.product .woocommerce-tabs ul.tabs:after, .woocommerce div.product div.thumbnails a.first, .woocommerce div.product div.thumbnails:after, .woocommerce ul.products, .woocommerce ul.products li.first, .woocommerce ul.products:after, .woocommerce-page #content div.product #reviews .comment:after, .woocommerce-page #content div.product .woocommerce-tabs, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page #content div.product div.thumbnails a.first, .woocommerce-page #content div.product div.thumbnails:after, .woocommerce-page .cart-collaterals:after, .woocommerce-page .col2-set:after, .woocommerce-page .related:after, .woocommerce-page .upsells.products:after, .woocommerce-page .woocommerce-pagination ul.page-numbers:after, .woocommerce-page div.product #reviews .comment:after, .woocommerce-page div.product .woocommerce-tabs, .woocommerce-page div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page div.product div.thumbnails a.first, .woocommerce-page div.product div.thumbnails:after, .woocommerce-page ul.products, .woocommerce-page ul.products li.first, .woocommerce-page ul.products:after {
	clear: both;
}
.woocommerce table.shop_table_responsive tbody th, .woocommerce table.shop_table_responsive thead, .woocommerce-page table.shop_table_responsive tbody th, .woocommerce-page table.shop_table_responsive thead {
	display: none
}
.woocommerce table.shop_table_responsive tbody tr:first-child td:first-child, .woocommerce-page table.shop_table_responsive tbody tr:first-child td:first-child {
	border-top: 0
}
.woocommerce table.shop_table_responsive tr, .woocommerce table.shop_table_responsive tr td, .woocommerce-page table.shop_table_responsive tr, .woocommerce-page table.shop_table_responsive tr td {
	display: block
}
.woocommerce #content table.cart .product-thumbnail, .woocommerce table.cart .product-thumbnail, .woocommerce table.my_account_orders tr td.order-actions:before, .woocommerce-page #content table.cart .product-thumbnail, .woocommerce-page table.cart .product-thumbnail, .woocommerce-page table.my_account_orders tr td.order-actions:before {
	display: none
}
.woocommerce table.shop_table_responsive tr td:before, .woocommerce-page table.shop_table_responsive tr td:before {
	content: attr(data-title) ": ";
	font-weight: 700
}
.woocommerce table.shop_table_responsive tr:nth-child(2n) td, .woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
	background-color: rgba(0,0,0,.025)
}
.woocommerce table.my_account_orders tr td.order-actions, .woocommerce-page table.my_account_orders tr td.order-actions {
	text-align: left
}
.woocommerce table.my_account_orders tr td.order-actions .button, .woocommerce-page table.my_account_orders tr td.order-actions .button {
	float: none;
	margin: .125em .25em .125em 0
}
.woocommerce .col2-set .col-1, .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-1, .woocommerce-page .col2-set .col-2 {
	float: none;
	width: 100%
}
.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
	width: 48%;
	float: left;
	margin: 0 0 2.992em
}/*;clear:both*/
/*.woocommerce ul.products li.product:nth-child(2n),.woocommerce-page ul.products li.product:nth-child(2n){float:right;clear:none!important}*/
.woocommerce ul.products li.product:nth-child(2n),.woocommerce-page ul.products li.product:nth-child(2n){float:right;clear:none!important}

.woocommerce #content div.product div.images, .woocommerce #content div.product div.summary, .woocommerce div.product div.images, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.images, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.images, .woocommerce-page div.product div.summary {
	float: none;
	width: 100%
}
.woocommerce #content table.cart td.actions, .woocommerce table.cart td.actions, .woocommerce-page #content table.cart td.actions, .woocommerce-page table.cart td.actions {
	text-align: left
}
.woocommerce #content table.cart td.actions .coupon, .woocommerce table.cart td.actions .coupon, .woocommerce-page #content table.cart td.actions .coupon, .woocommerce-page table.cart td.actions .coupon {
	float: none;
	padding-bottom: .5em
}
.woocommerce #content table.cart td.actions .coupon:after, .woocommerce #content table.cart td.actions .coupon:before, .woocommerce table.cart td.actions .coupon:after, .woocommerce table.cart td.actions .coupon:before, .woocommerce-page #content table.cart td.actions .coupon:after, .woocommerce-page #content table.cart td.actions .coupon:before, .woocommerce-page table.cart td.actions .coupon:after, .woocommerce-page table.cart td.actions .coupon:before {
	content: " ";
	display: table
}
.woocommerce #content table.cart td.actions .coupon:after, .woocommerce table.cart td.actions .coupon:after, .woocommerce-page #content table.cart td.actions .coupon:after, .woocommerce-page table.cart td.actions .coupon:after {
	clear: both
}
.woocommerce #content table.cart td.actions .button, .woocommerce #content table.cart td.actions .input-text, .woocommerce #content table.cart td.actions input, .woocommerce table.cart td.actions .button, .woocommerce table.cart td.actions .input-text, .woocommerce table.cart td.actions input, .woocommerce-page #content table.cart td.actions .button, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions input, .woocommerce-page table.cart td.actions .button, .woocommerce-page table.cart td.actions .input-text, .woocommerce-page table.cart td.actions input {
	width: 48%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.woocommerce #content table.cart td.actions .button.alt, .woocommerce #content table.cart td.actions .input-text+.button, .woocommerce table.cart td.actions .button.alt, .woocommerce table.cart td.actions .input-text+.button, .woocommerce-page #content table.cart td.actions .button.alt, .woocommerce-page #content table.cart td.actions .input-text+.button, .woocommerce-page table.cart td.actions .button.alt, .woocommerce-page table.cart td.actions .input-text+.button {
	float: right
}
.woocommerce .cart-collaterals .cart_totals, .woocommerce .cart-collaterals .cross-sells, .woocommerce .cart-collaterals .shipping_calculator, .woocommerce-page .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cross-sells, .woocommerce-page .cart-collaterals .shipping_calculator {
	width: 100%;
	float: none;
	text-align: left
}
.woocommerce-page.woocommerce-checkout form.login .form-row, .woocommerce.woocommerce-checkout form.login .form-row {
	width: 100%;
	float: none
}
.woocommerce #payment .terms, .woocommerce-page #payment .terms {
	text-align: left;
	padding: 0
}
.woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
	float: none;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 1em
}
.woocommerce .lost_reset_password .form-row-first, .woocommerce .lost_reset_password .form-row-last, .woocommerce-page .lost_reset_password .form-row-first, .woocommerce-page .lost_reset_password .form-row-last {
	width: 100%;
	float: none;
	margin-right: 0
}
.single-product .twentythirteen .panel {
	padding-left: 20px!important;
	padding-right: 20px!important
}

}
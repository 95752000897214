/**
 * Alpine PhotoTile Widget CSS
 *
 * @since 0.0.1
 * @updated 1.2.6
 */

/* The style of the Display Link below the images */
/* Global styles for the container and parent class */
.AlpinePhotoTiles_container_class{
  position:relative;
  width:100%;
  display:block;
  overflow:hidden;
}
.AlpinePhotoTiles_container_class img{
  margin:0;
  margin-bottom: 4px;
}
.AlpinePhotoTiles_container_class.loading{
  min-height:200px;
  width:100%;
  background-image:url("../../img/loader.gif");
  background-position:center;
  background-repeat:no-repeat;
  background-size:40px 40px;
  background-color:#fff;
  border-radius: 3px 3px 3px 3px;
}
.AlpinePhotoTiles_inpost_container {
  padding-bottom:10px;
  display:block;
  clear:both;
  width:100%;
  overflow:hidden;
}

img.AlpinePhotoTiles-img-corners{
  border-radius: 3px 3px 3px 3px ! important;
}
img.AlpinePhotoTiles-img-nocorners{
  border-radius: 0 0 0 0 ! important;
}

.AlpinePhotoTiles_cascade_column{
  margin:0;
  padding:0;
  max-width:100%;
  float:left;
}

.AlpinePhotoTiles_breakline{
  width:100%;
  float:left;
  position:relative;
  clear:both;
  height:1px;
}


.AlpinePhotoTiles_parent_class{
  position:relative;
  max-width:100%;
  clear:both;
  display:block;
  height:auto;
  overflow: hidden;
}


.AlpinePhotoTiles-row{
  max-width:100%;
  position:relative;
  padding: 0 0 4px 0;
  clear:both;
  display:block;
  height:auto;
}
.AlpinePhotoTiles-tile{
  display:block;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;
  margin:0 0 0 4px;
  float:left;
}
.AlpinePhotoTiles-half-tile{

}
.AlpinePhotoTiles-half-tile-first{

}
.AlpinePhotoTiles-half-tile-last{

}

.AlpinePhotoTiles-bookshelf{
  padding: 5px 0;
}
.AlpinePhotoTiles-book{
  position:absolute;
  bottom:5px;
  margin:0 0 0 4px;
}

.AlpinePhotoTiles-riftline{
  padding: 0 0 5px 0;
}
.AlpinePhotoTiles-rift{
  position:absolute;
  bottom:5px;
  margin:0 0 0 4px;
}
.AlpinePhotoTiles-rift.AlpinePhotoTiles-float-0{
  top:0px;
}
.AlpinePhotoTiles-rift.AlpinePhotoTiles-float-1{
  bottom:5px;
}


.AlpinePhotoTiles-image-div-container{
  overflow:hidden;
}
.AlpinePhotoTiles-gallery-container{
  margin:0 0 0 4px;
}

.AlpinePhotoTiles-image-div{
	position:relative;
  display:block;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;
  width:100%;
  height:100%;
}
.AlpinePhotoTiles-link{
  width:100%;
  height:100%;
  display:block;
  position:relative;
	overflow:hidden;
}
.AlpinePhotoTiles-border-div{
  background: none repeat scroll 0 0 #FFFFFF;
  padding:5px;
}
.AlpinePhotoTiles-highlight-div{
  padding:2px;
}


.AlpinePhotoTiles-shadow-div{
  box-shadow: 1px 1px 3px rgba(34, 25, 25, 0.4);
}
.AlpinePhotoTiles-curve-div{
  border-radius: 3px 3px 3px 3px;
}


.AlpinePhotoTiles-display-link{
  width:100%;
  text-align:center;
  /*font-size:10px; */
  margin:3px 0 3px 0;
  position:relative;
}
.AlpinePhotoTiles-image-link{
  margin:0px;
  border:0px;
  padding:3px 0 3px 0;
}
.AlpinePhotoTiles-display-link-container{
  position:relative;
  clear:both;
  display:block;
  width:100%;
}



/* Styles specific to the images of each display style */
.AlpinePhotoTiles-vertical-img{
  position:relative;
  width:100%;
  margin:4px 0 0 0;
  padding:0;
  border:0;
  outline:0;
}
.AlpinePhotoTiles-vertical-link{
  line-height:0;
  display:block;
}
.AlpinePhotoTiles-tile-img{
  width:100%;
  margin:0;
  padding:0;
  border:0;
  outline:0;
}


/* Style for the little corner link to ETH & KU */
.AlpinePhotoTiles-by-link{
  position:absolute;
  bottom:0px;
  left:5px;
  display:block;
  height:auto;
  color:#C0C0C0;
  padding:5px;
  text-align:left;
  font-size:12px;
  z-index:100;
}
.AlpinePhotoTiles-by-link a{
  font-size:8px;
  line-height:8px;
  opacity:0.9;
  filter:alpha(opacity=90);
  COLOR:#C0C0C0;
  text-decoration:none;
}

.AlpinePhotoTiles-pinterest-container{
  display:block;
  position:relative;
}
.AlpinePhotoTiles-pin-it-button{
  display:block;
  width:100%;
  height:100%;
}
.AlpinePhotoTiles-pinterest-container:hover .AlpinePhotoTiles-pin-it {
  opacity:1;
}
.AlpinePhotoTiles-pin-it{
  background-image: url('../../img/pin-it.png');
  width:43px;
  height:21px;
  max-height:40%;
  max-width:40%;
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  position:absolute;
  bottom:18px;
  right:15px;
  opacity:0;
}
.AlpinePhotoTiles-pin-it.small{
  bottom:7px;
  right:9px;
}
.AlpinePhotoTiles-pin-it:hover{
  background-color:rgba(34, 25, 25, 0.6);
  box-shadow: 0 0 3px rgba(34, 25, 25, 0.6);
}


.woocommerce-page ul
.product{float:left !important;margin-right:3.125% !important;width:31.25% !important}.woocommerce ul.products li.last, .woocommerce-page ul.products
li.last{margin-right:0 !important}.woocommerce
span.onsale{border-radius:0;color:#333;background:#fff;font-size:11px;display:block;right:auto !important;left:-7px !important;top:15px !important;min-height:0;text-transform:uppercase;margin:0
!important;line-height:11px !important;padding:15px
5px;border:3px
solid #999;font-weight:normal}.woocommerce ul.products li.product .star-rating{font-size:13px;color:#333;bottom:70px;right:8px;overflow:hidden;position:absolute;height:1em;line-height:1em;width:5.4em}.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce
input.button{border:1px
solid #333;border-radius:0;font-weight:300;font-size:13px;text-transform:uppercase;background-color:#fff;-webkit-transition:all .3s !important;-moz-transition:all .3s !important;-ms-transition:all .3s !important;-o-transition:all .3s !important;transition:all .3s !important}.woocommerce
input.button.alt{background-color:#fff;color:#333}.woocommerce
a.button.checkout{background:#333;color:#fff}.woocommerce ul.products li.product
.price{margin-bottom:0;color:#333 !important;padding:4px
0}.woocommerce ul.products li.product .price del, .woocommerce ul.products li.product .price
ins{display:inline-block;margin-right:5px}.woocommerce ul.products li.product .price
del{font-size:14px}.woocommerce ul.products li.product
h3{text-transform:uppercase !important;padding:5px
0 !important;line-height:1em !important;font-size:13px !important;font-weight:300 !important}.woocommerce ul.products li.product a
img{margin-bottom:5px !important}.woocommerce ul.products li.product
.add_to_cart_button{position:absolute;bottom:0;border:1px
solid #111;border-radius:0;right:-20px;background:#fff;opacity:0 !important;text-transform:uppercase;padding:10px
12px;font-weight:300;visibility:hidden;letter-spacing:1px !important;transition:all .3s !important;-webkit-transition:all .3s !important}.woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover{color:#fff !important;background:#111 !important}.woocommerce ul.products li.product:hover .add_to_cart_button, .woocommerce ul.products li.product:focus .add_to_cart_button, .woocommerce ul.products li.product .add_to_cart_button.added, .woocommerce ul.products li.product
.add_to_cart_button.loading{visibility:visible !important;opacity:1 !important;right:8px}.woocommerce-ordering{font-size:13px !important}.woocommerce .woocommerce-ordering
select{font-weight:inherit !important;padding:3px
7px;border:1px
solid #999;line-height:1em !important;outline:none;letter-spacing:1px !important;border-radius:0}.woocommerce .woocommerce-ordering
option{font-weight:300 !important}.woocommerce .woocommerce-result-count, .woocommerce-page .woocommerce-result-count, .woocommerce .woocommerce-ordering, .woocommerce-page .woocommerce-ordering{margin-bottom:20px}.woocommerce .woocommerce-result-count, .woocommerce-page .woocommerce-result-count{padding:3px
7px;font-size:13px;font-style:italic}.woocommerce #respond input#submit.loading, .woocommerce a.button.loading, .woocommerce button.button.loading, .woocommerce
input.button.loading{padding-right:2.618em !important}.woocommerce #respond input#submit.loading:after, .woocommerce a.button.loading:after, .woocommerce button.button.loading:after, .woocommerce input.button.loading:after{margin-top:3px !important}.woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message{background:#fff;border:3px
solid #999 !important}.woocommerce .woocommerce-error a, .woocommerce .woocommerce-info a, .woocommerce .woocommerce-message
a{text-decoration:underline;margin-left:7px}.woocommerce .woocommerce-info:before{color:#999}.woocommerce
a.added_to_cart{position:absolute;top:0;line-height:24px;right:0;background:#333;color:#fff;padding:0
7px;font-size:11px;border:1px
solid #333;transition:all .3s;-webkit-transition:all .3s}.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce
form.register{background:#eee;border-radius:0;border:none}.woocommerce a.added_to_cart:hover, .woocommerce a.added_to_cart:focus{background:#fff;color:#333}.woocommerce-cart .cart-collaterals .cross-sells ul.products
li.product{width:70% !important}.woocommerce
table.shop_table{border:1px
dashed rgba(0, 0, 0, .1);border-left:none;border-bottom:none;font-size:15px}.woocommerce table.shop_table
td{border-top:0;text-align:center}.woocommerce-cart table.cart
img{width:70px;height:auto}.woocommerce
a.remove{font-size:18px !important;display:inline-block !important;width:18px !important;line-height:14px !important;height:18px !important;color:#999 !important;padding:0
!important;font-weight:normal;text-decoration:none !important;text-align:center !important;margin:0
!important;transition:all .3s;-webkit-transition:all .3s}.woocommerce a.remove:hover{background:#333}.woocommerce table.shop_table
th{font-weight:normal;text-align:center;text-transform:uppercase;padding:15px
0}.woocommerce .quantity
.qty{padding:10px
0;outline:none}.woocommerce-cart table.cart td.actions .coupon .input-text{padding: .618em 1em;font-size:13px !important;line-height:1em !important;min-width:120px;margin-right:10px}.woocommerce table.shop_table
td.actions{padding:15px
15px}.woocommerce-page .cart-collaterals .cart_totals h2, .woocommerce .cart-collaterals .cross-sells, .woocommerce-page .cart-collaterals .cross-sells
h2{font-size:18px}.woocommerce .cart-collaterals
.cart_totals{font-size:14px}.woocommerce-cart .cart-collaterals .cart_totals tr
th{width:40%;border-lefT:1px dashed rgba(0, 0, 0, .1);padding:10px
15px}.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr
th{border-bottom:0}.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li
img{width:78px;height:auto}.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget
li{padding:10px
0}.woocommerce-cart .wc-proceed-to-checkout a.checkout-button{background:#333;color:#fff;font-size:16px;font-weight:300;padding:15px;border:1px
solid #333;-webkit-transition:all .3s !important;-moz-transition:all .3s !important;-ms-transition:all .3s !important;-o-transition:all .3s !important;transition:all .3s !important}.woocommerce-cart .cart-collaterals .cart_totals
table{border-bottom:1px dashed rgba(0, 0, 0, .1)}.woocommerce-cart .cart-collaterals .cart_totals table tr td, .woocommerce-cart .cart-collaterals .cart_totals table tr
th{border-left:1px dashed rgba(0, 0, 0, .1);padding:15px
10px}.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr
th{border-top:1px dashed rgba(0, 0, 0, .1)}.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus{background:#fff !important;color:#333 !important}.woocommerce #review_form .form-submit{border:0}.woocommerce ul.cart_list li a, .woocommerce ul.product_list_widget li
a{text-transform:uppercase !important;padding:5px
0 !important;line-height:1.5em !important;font-size:13px !important;font-weight:300 !important}.woocommerce ul.cart_list li a:hover, .woocommerce ul.product_list_widget li a:hover, .woocommerce ul.cart_list li a:focus, .woocommerce ul.product_list_widget li a:focus{text-decoration:underline}.woocommerce ul.product_list_widget li
del{margin-right:7px}.woocommerce ul.cart_list li span.reviewer, .woocommerce ul.product_list_widget li
span.reviewer{font-size:14px;margin-top:5px;font-style:italic;line-height:1.5em;display:block}.woocommerce ul.cart_list li .star-rating, .woocommerce ul.product_list_widget li .star-rating{font-size:11px;margin-top:4px;margin-bottom:4px}.woocommerce ul.product_list_widget li
span.amount{display:inline-block;margin-top:5px;line-height:1em !important}.woocommerce .widget_shopping_cart .total, .woocommerce.widget_shopping_cart
.total{border-top:0;padding:10px;background:#e2e2e2;margin-top:10px;font-size:16px;margin-bottom:15px}.woocommerce .widget_shopping_cart .cart_list li a.remove, .woocommerce.widget_shopping_cart .cart_list li
a.remove{height:18px !important;width:18px !important;display:block;line-height:18px !important;padding:0
!important;margin-top:7px !important}.woocommerce .widget_price_filter .price_slider_amount
.button{font-size:13px}.woocommerce .widget_price_filter
.price_slider{margin-bottom:30px}.woocommerce .widget_price_filter .ui-slider-horizontal{height:4px !important}.woocommerce .widget_price_filter .ui-slider .ui-slider-handle{top:-6px;border-radius:0;border:1px
solid #333;background-color:#fff}.woocommerce #reviews #comments ol.commentlist li .comment-text{border-radius:0}.woocommerce .widget_price_filter .ui-slider .ui-slider-range{background-color:#333}.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content{background-color:#999}.widget_product_search
form{position:relative;overflow:hidden}.woocommerce #review_form #respond
p{margin:0
!important;padding:15px
0 !important}.woocommerce .comment-respond
label{display:block}.woocommerce .comment-respond
.required{color:#e54747}.woocommerce .comment-form-email{padding:0}.widget_product_search input[type="search"]{display:block;padding:0
15px;height:46px;width:100%;margin-right:40px;border:1px
solid #999;color:#999;outline:0}.widget_product_search input[type="submit"]{position:absolute;right:0;top:0;background:#26282A;height:48px;text-transform:uppercase;border:0;padding:0;width:90px;color:#fff;outline:none}.woocommerce div.product .woocommerce-tabs
ul.tabs{padding:0
0 0 0}.woocommerce div.product .woocommerce-tabs ul.tabs
li{border-radius:0;text-transform:uppercase;background:#fff;border-left:1px solid #d3ced2;border-top:1px solid #d3ced2;margin:0}.woocommerce div.product .woocommerce-tabs ul.tabs
li.reviews_tab{margin-left:-5px}.woocommerce div.product .woocommerce-tabs ul.tabs
li.active{background:#333;color:#fff;border-color:#333}.woocommerce div.product .woocommerce-tabs ul.tabs li:after, .woocommerce div.product .woocommerce-tabs ul.tabs li:before{border:0;border-radius:0;height:0;width:0}.woocommerce div.product .woocommerce-tabs ul.tabs li
a{font-weight:300;font-size:15px}.woocommerce-tabs
h2{margin-bottom:24px}.woocommerce #reviews #comments ol.commentlist li
img.avatar{border-radius:50%;width:42px;height:auto;display:block}.woocommerce-noreviews{font-size:14px;font-weight:400;color:#999;margin-bottom:15px}.woocommerce div.product
.product_title{text-transform:uppercase !important;line-height:1em !important;font-size:18px !important;font-weight:300 !important}.woocommerce div.product
p.price{font-size:32px !important;display:block;margin:32px
0 !important;color:#111 !important}.woocommerce div.product
form.cart{margin-top:32px;display:block}.woocommerce div.product .quantity
.qty{font-size:18px;line-height:32px !important}.woocommerce div.product form.cart
.button{font-size:18px !important;background:#333;letter-spacing:1px;text-shadow:1px 1px 1px rgba(0, 0, 0, .1);line-height:32px !important;-webkit-transition:all .3s !important;-moz-transition:all .3s !important;-ms-transition:all .3s !important;-o-transition:all .3s !important;transition:all .3s !important}.woocommerce div.product form.cart .button:hover, .woocommerce div.product form.cart .button:focus{background-color:#fff !important;color:#333 !important}.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message
.button{text-decoration:none !important;background-color:#333;color:#fff}.woocommerce div.product .woocommerce-product-rating{margin-top:10px;margin-bottom:0}.product_meta
a{display:inline-block;margin-right:7px}.woocommerce #respond input#submit.alt.disabled, .woocommerce #respond input#submit.alt.disabled:hover, .woocommerce #respond input#submit.alt:disabled, .woocommerce #respond input#submit.alt:disabled:hover, .woocommerce #respond input#submit.alt:disabled[disabled], .woocommerce #respond input#submit.alt:disabled[disabled]:hover, .woocommerce a.button.alt.disabled, .woocommerce a.button.alt.disabled:hover, .woocommerce a.button.alt:disabled, .woocommerce a.button.alt:disabled:hover, .woocommerce a.button.alt:disabled[disabled], .woocommerce a.button.alt:disabled[disabled]:hover, .woocommerce button.button.alt.disabled, .woocommerce button.button.alt.disabled:hover, .woocommerce button.button.alt:disabled, .woocommerce button.button.alt:disabled:hover, .woocommerce button.button.alt:disabled[disabled], .woocommerce button.button.alt:disabled[disabled]:hover, .woocommerce input.button.alt.disabled, .woocommerce input.button.alt.disabled:hover, .woocommerce input.button.alt:disabled, .woocommerce input.button.alt:disabled:hover, .woocommerce input.button.alt:disabled[disabled], .woocommerce input.button.alt:disabled[disabled]:hover{background-color:#fff;color:#333}.widget_product_search .woocommerce-product-search
label{display:none}.woocommerce div.product p.price
del{margin-bottom:15px;font-size: .9em}.product_meta
a{display:inline-block;padding:6px
9px;margin:1px
6px;border:1px
dashed rgba(0, 0, 0, .1);line-height:1em;font-size:13px;text-transform:none;color:#333;font-weight:300;letter-spacing:1px}.product_meta a:hover, .product_meta a:focus{text-decoration:underline}.product_meta{padding-top:12px;padding-bottom:12px;border-top:1px dashed rgba(0, 0, 0, .1);border-bottom:1px dashed rgba(0, 0, 0, .1);margin-bottom:12px;margin-top:30px;display:block}.product_meta .posted_in, .product_meta
.sku_wrapper{display:block}.product_meta
.sku_wrapper{margin-bottom:12px}.single_variation
span.price{color:#333 !important;font-size:18px !important;margin-bottom:7px;display:block}.woocommerce div.product form.cart
.variations{margin-bottom:24px !important}.woocommerce div.product form.cart .variations
select{width:auto;float:left;padding:3px
10px;letter-spacing:1px;font-size:14px;line-height:1em;border:1px
solid #999;outline:none}.woocommerce div.product form.cart .variations
label{font-weight:300;padding:3px
0;display:block;line-height:1em;margin-right:7px}.reset_variations{color:#999;font-size:12px;white-space:nowrap;margin-left:7px}.woocommerce .widget_shopping_cart .total strong, .woocommerce.widget_shopping_cart .total
strong{margin-right:7px}.select2-container .select2-choice{padding:12px;border:1px
solid #999;border-radius:0;font-size:inherit;font-weight:inherit;text-transform:capitalize;letter-spacing:1px}.woocommerce-checkout #payment ul.payment_methods
li{list-style:none !important}.woocommerce form
textarea{background:#fff;padding:12px;border:1px
solid #999}.woocommerce table.shop_table tbody th, .woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot
th{border-top:0}form.checkout_coupon input[type="submit"]{padding:12px;font-size:inherit !important}.woocommerce .woocommerce-error li, .woocommerce .woocommerce-info li, .woocommerce .woocommerce-message
li{margin-top:0 !important}.woocommerce form .form-row:last-child{margin-bottom:0}.woocommerce form .form-row
label.inline{margin-left:7px}.woocommerce-billing-fields,.woocommerce-shipping-fields{margin-top:32px}.woocommerce #payment #place_order, .woocommerce-page #payment
#place_order{background:#333;font-size:18px;text-shadow:1px 1px 1px rgba(0, 0, 0, .1);color:#fff}.woocommerce #payment #place_order:hover, .woocommerce-page #payment #place_order:hover, .woocommerce #payment #place_order:focus, .woocommerce-page #payment #place_order:focus{color:#333 !important;background:#fff !important}div.pp_woocommerce
div.ppt{text-transform:uppercase;font-weight:300}.woocommerce.woocommerce-page .page-title{font-size:1em !important;line-height:1em !important;padding:24px
0}